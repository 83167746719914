'use client';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { log } from 'winston';
import { useDispatch } from 'react-redux';

// export const MessageListener = () => {
// 	const router = useRouter();

// 	useEffect(() => {
// 		const handleMessage = (event: any) => {
// 			console.log(`До праса :${event.data}`);

// 			if (JSON.parse(event.data)[0] === 'updateArticles') {
// 				console.log(`После праса :${JSON.parse(event.data)}`);
// 				const url = new URL(window.location.href);
// 				url.searchParams.set('reloaded', 'true');
// 				window.location.href = url.toString();
// 			}
// 		};

// 		document.addEventListener('message', handleMessage);

// 		return () => {
// 			document.removeEventListener('message', handleMessage);
// 		};
// 	}, []);

// 	useEffect(() => {
// 		const url = new URL(window.location.href);
// 		if (url.searchParams.get('reloaded') === 'true') {
// 			console.log(
// 				`отправка setTeasersLoadingState:  ${JSON.stringify([
// 					'setTeasersLoadingState',
// 					false
// 				])}`
// 			);
// 			window.postMessage(
// 				(JSON.stringify(['setTeasersLoadingState', false]), '*')
// 			);
// 			url.searchParams.delete('reloaded');
// 			router.replace(url.pathname + url.search);
// 		}
// 	}, [router]);

// 	return null;
// };

export const Message = ({ funct, rest }: any) => {
	const [refresh, setRefresh] = useState<boolean>(false);
	const dispatch = useDispatch();
	useEffect(() => {
		const handleMessage = (event: any) => {
			const JsonValidate = () => {
				try {
					JSON.parse(event?.data)[0];
				} catch (e) {
					return false;
				}
				return true;
			};
			if (JsonValidate() && JSON.parse(event?.data)[0] === 'updateArticles') {
				// const url = new URL(window.location.href);
				// url.searchParams.set('reloaded', 'true');
				// window.location.href = url.toString();
				setRefresh(true);

				dispatch(rest());
				dispatch(funct);
			}
		};

		document.addEventListener('message', handleMessage);

		return () => {
			document.removeEventListener('message', handleMessage);
		};
	}, [funct]);

	useEffect(() => {
		if (refresh) {
			if (window.ReactNativeWebView !== undefined) {
				window.ReactNativeWebView.postMessage(
					JSON.stringify(['setTeasersLoadingState', false])
				);
			}

			setRefresh(false);
		}
	}, [refresh]);
	return null;
};
